//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-96:_4880,_7080,_6851,_8132,_1552,_1536,_8847,_7424;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-96')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-96', "_4880,_7080,_6851,_8132,_1552,_1536,_8847,_7424");
        }
      }catch (ex) {
        console.error(ex);
      }